<template>
  <p class="ciam-help-text">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'ciam-help-text',
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.ciam-help-text {
  @apply mt-2 text-sm text-gray-500;
}
</style>
