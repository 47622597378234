<template>
  <ciam-page :title-suffix="$t('pageTitles.organization')">
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>
          <ciam-text>{{ $t('organization.members.invite.header', { name: organizationName }) }}</ciam-text>
        </template>
        <template v-slot:subtitle>{{ $t('organization.members.invite.subtitle') }}</template>
      </ciam-card-header>

      <template>
        <ciam-card-content>
          <ciam-card-content-lines>
            <ciam-card-content-line>
              <template v-slot:label>
                <ciam-text>{{ $t('organization.members.invite.email') }}</ciam-text>
              </template>
              <template v-slot:content>
                <ciam-alert
                  v-if="problem !== null"
                  title="404 - User Not Found"
                  :description="displayUserNotFoundProblem(problem.detail)"
                  :type="AlertStatus.WARNING_HREF"
                  href="https://documentation.cloud-iam.com/get-started/access-to-cloud-iam-app.html#create-an-account"
                  hrefText="Here is documentation on the account creation process"
                >
                </ciam-alert>
                <ciam-input type="email" aria-required="true" v-model="form.email"></ciam-input>
                <ciam-help-text>{{ $t('organization.members.invite.emailHelp') }}</ciam-help-text>
              </template>
            </ciam-card-content-line>
            <ciam-roles v-model="form.role" />
          </ciam-card-content-lines>
        </ciam-card-content>
      </template>

      <ciam-card-footer>
        <ciam-button class="outlined" @click="cancel()">{{ $t('actions.cancel') }}</ciam-button>
        <ciam-button class="primary" :disabled="!isValid" @click="applyInviteMember()">
          {{ $t('organization.members.invite.action') }}
        </ciam-button>
      </ciam-card-footer>
    </ciam-card>
  </ciam-page>
</template>

<script>
import CiamAlert from '@/components/CiamAlert';
import { AlertStatus } from '@/components/CiamAlert';
import OrganizationService from '@/pages/organizations/OrganizationService';
import CiamSelect from '@/components/CiamSelect';
import CiamInput from '@/components/CiamInput';
import { Notification } from 'vue-notifyjs';
import i18n from '@/i18n';
import RoleService from '@/pages/RoleService';
import { routeNames } from '@/router';
import CiamList from '@/components/CiamList';
import CiamListItem from '@/components/CiamListItem';
import CiamHelpText from '@/components/CiamHelpText';
import Maybe from 'data.maybe-fgribreau';
import CommonService from '@/pages/commonService';
import CiamRoles from '@/components/CiamRoles.vue';

export default {
  name: 'InviteMember',
  components: {
    CiamRoles,
    CiamHelpText,
    CiamInput,
    CiamAlert,
  },
  data() {
    return {
      AlertStatus: AlertStatus,
      loadingPromise: new Promise(() => {
      }),
      problem: null,
      organizationName: '...',
      roles: [],
      form: {
        email: '',
        role: null,
      },
    };
  },
  computed: {
    isValid() {
      return this.form.email.length > 5 && this.form.role !== null && this.form.role.length > 0;
    },
    organizationId() {
      return this.$route.params.id;
    },
  },
  methods: {
    displayUserNotFoundProblem(problemDetail) {
      return problemDetail == 'The requested user does not exist' ? '🤔 It seems that the user you want to add to your organization does not have a Cloud-IAM account yet. We recommend sending them a request to create an account, and to assist.' : problemDetail;
    },
    cancel() {
      this.$router
        .push({
          name: routeNames.OrganizationDetails,
          query: {
            organization_id: this.organizationId,
          },
          params: { id: this.organizationId },
        })
        .catch(() => {
        });
    },
    applyInviteMember() {
      OrganizationService.inviteMember(this.organizationId, this.form.email, this.form.role)
        .then(() => {
          Notification.notify({
            message: i18n.t('notifications.memberHasBeenInvited', {
              email: this.form.email,
            }),
            type: 'info',
          });
          this.$router
            .push({
              name: routeNames.OrganizationDetails,
              query: {
                organization_id: this.organizationId,
              },
              params: { id: this.organizationId },
            })
            .catch(() => {
            });
        })
        .catch((problem) => {
          this.problem = problem;
        });
    },
  },
};
</script>
